import React from "react";
import { Grid, Typography, Box, Button, BottomNavigationAction, List, ListItem, ListItemText } from "@mui/material";
import styles from './styles.module.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { clientInfo } from "../utils/companyData";
import themeColors from "../utils/themeColors";
import Logo from './Logo';

export default function ContactBanner() {
  const { phone, phoneAction, phone2, phoneAction2, whatsappAction, whatsappAction2, owner } = clientInfo;
  const transportRoutes = {
    route1: "Transfer aeroport Botoșani - Suceava",
    route2: "Transfer aeroport Botoșani - Iași",
    route3: "Transfer aeroport Botoșani - Cluj",
    route4: "Transfer aeroport Botoșani - Orice județ limitrof și din țară",
    route5: "Rezervări transfer aeroport"
  };
  const welcomeMessage = "Bine ai venit la Botoșani Taxi! Ne puteți contacta pe WhatsApp sau pe telefon. Ne va bucura să te ajutăm!";
  const carIllustration = process.env.PUBLIC_URL + '/images/flaingJet.png';
  return (
    <Box className={styles.boxInfo} sx={{ padding: { xs: '20px', md: '40px' }, maxWidth: '1200px', margin: '0 auto' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Grid item xs={12} sm={12} md={2} lg={1} container justifyContent="center" alignItems="center">
            {/* Display the Logo for larger screens */}
            <BottomNavigationAction
              icon={<Logo />} sx={{ display: { xs: 'block', lg: 'none', md: 'none', sm: 'none' } }}
            />
          </Grid>
          <section id="top">
            <Typography variant="h6" paragraph sx={{ fontWeight: 'bold' }}>
              Solicită o cursă în oraș sau în afara orașului!
            </Typography>
          </section>
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontFamily: 'Roboto Slab', fontOpticalSizing: 'auto', fontWeight: 'normal', display: { xs: 'none', lg: 'block', md: 'block', sm: 'block' } }}>
            {owner}
          </Typography>
          <Box sx={{
            display: 'flex',
            gap: { xs: '10px', lg: '10px' },
            marginBottom: '20px',
            marginLeft: { xs: '0', lg: '30px' },
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: { xs: 'column', sm: 'row' }
          }}>
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: `${themeColors.phone}`,
                border: '1px solid black',
                width: { xs: '100%', sm: '320px' },
                minHeight: '60px',
                fontWeight: 'bold',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid gray',
                  backgroundColor: `${themeColors.phone}`,
                },
              }}
              onClick={phoneAction}>
              <LocalPhoneIcon sx={{ fontSize: { xs: 35, sm: 30 } }} />
              &nbsp;
              <Typography sx={{ color: 'inherit', textTransform: 'none', fontSize: { xs: 20, sm: 20 }, fontWeight: 'bold' }}>{phone}</Typography>
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: `${themeColors.whatsapp}`,
                border: '1px solid green',
                fontWeight: 'bold',
                width: { xs: '100%', sm: '320px' },
                minHeight: '60px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid green',
                  backgroundColor: `${themeColors.whatsapp}`,
                },
              }}
              onClick={whatsappAction}
            >
              <WhatsAppIcon sx={{ fontSize: { xs: 35, sm: 40 } }} />
              &nbsp;
              <Typography sx={{ color: 'inherit', textTransform: 'none', fontSize: { xs: 20, sm: 20 }, fontWeight: 'bold' }}>WhatsApp</Typography>
            </Button>
          </Box>
          {/* second box */}
          <Box sx={{
            display: 'flex',
            gap: { xs: '10px', lg: '10px' },
            marginBottom: '20px',
            marginLeft: { xs: '0', lg: '30px' },
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: { xs: 'column', sm: 'row' }
          }}>
            <Button
              color="primary"
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: `${themeColors.whatsapp}`,
                border: '1px solid green',
                fontWeight: 'bold',
                width: { xs: '100%', sm: '320px' },
                minHeight: '60px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid green',
                  backgroundColor: `${themeColors.whatsapp}`,
                },
              }}
              onClick={whatsappAction2}
            >
              <WhatsAppIcon sx={{ fontSize: { xs: 35, sm: 40 } }} />
              &nbsp;
              <Typography sx={{ color: 'inherit', textTransform: 'none', fontSize: { xs: 20, sm: 20 }, fontWeight: 'bold' }}>WhatsApp</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: `${themeColors.phone}`,
                border: '1px solid black',
                width: { xs: '100%', sm: '320px' },
                minHeight: '60px',
                fontWeight: 'bold',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  border: '1px solid gray',
                  backgroundColor: `${themeColors.phone}`,
                },
              }}
              onClick={phoneAction2}>
              <LocalPhoneIcon sx={{ fontSize: { xs: 35, sm: 30 } }} />
              &nbsp;
              <Typography sx={{ color: 'inherit', textTransform: 'none', fontSize: { xs: 20, sm: 20 }, fontWeight: 'bold' }}>{phone2}</Typography>
            </Button>


          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph sx={{ fontWeight: 'bold' }}>
                BOTOȘANI TAXI - RAPID TRANSFER AEROPORT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" paragraph>
                {welcomeMessage}
              </Typography>
            </Grid>
            {/* Image in text */}
            <Grid item xs={12} md={6} sx={{ display: { xs: 'block', sm: 'none' } }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={carIllustration} alt="Car Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 0 }}>
                {Object.values(transportRoutes).map((route, index) => (
                  <ListItem key={index} sx={{ width: '100%', padding: '0 8px' }} >
                    <ListItemText primary={<Typography variant="body2">• {route}</Typography>} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={carIllustration} alt="Car Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
